








import Vue from "vue";
export default Vue.extend({
  name: "Home",

  computed: {
    CurrentRoute: function () {
      return this.$route.query.example
    },
    CurrentView: function () {
     const currentView = this.CurrentRoute;
      return () => import(`@/views/examples/${currentView}.vue`);
    },
  },
});
